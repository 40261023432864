import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © ADWatchList.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <Link to="/privacy-policy">Privacy Policy</Link> {" | "}
                <Link to="/terms-and-conditions">Terms And Conditions</Link>
                {" | Design & Develop by AD Watch List"}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
