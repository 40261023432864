import { post, del, get, put, patch } from './api_helper';
import * as url from './url_helper';
import axios from "axios";

export const retrieveRefreshToken = () => get(`${url.REFRESH_TOKEN}`);

// LIKES
//export const addLike = (data) => post(url.ADD_LIKE, data);
//export const getLikes = (date, side) => get(`${url.GET_LIKES}/${date}/${side}`)
// Login Method
export const postLogin = (data) => post(url.LOGIN, data);

export const postRegister = (data) => post(url.REGISTER, data);

export const addWatch = (data) => post(url.ADD_WATCH, data);

export const getBrandModels = () => get(url.GET_BRAND_MODELS);

export const getInventory = () => get(url.GET_ALL_INVENTORY);

export const getUserInventory = () => get(url.GET_ALL_USER_INVENTORY);

export const updateUserInventory = (data) => patch(url.UPDATE_USER_INVENTORY, data);

// Register Method
export const postJwtRegister = (data) => {  
  return axios
    .post(url.REGISTER, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found';
            break;
          case 500:
            message = 'Sorry! something went wrong, please contact our support team';
            break;
          case 401:
            message = 'Invalid credentials';
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

export const getLoggedInUser = () => {
  const user = localStorage.getItem('user');  
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Edit profile
export const postJwtProfile = (data) => post(url.POST_EDIT_JWT_PROFILE, data);
