import axios from 'axios';

//apply base url for axios
let API_URL;

if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://adwatchlistapi.onrender.com';
} else {
  API_URL = 'http://localhost:6969';
}

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export async function get(url, config = {}) {
  let authUser = localStorage.getItem('authUser');
  authUser = JSON.parse(authUser) || null;
  if (authUser !== undefined && authUser !== null && authUser.token !== undefined) {
    axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.token;
  }
  try {
    const r = await axiosApi.get(url, { ...config });
    return await r.data;
  } catch (error) {
    if (error && error.response) {
      return error.response;
    } else {
      return error;
    }
  }
}

export async function post(url, data, config = {}) {  
  let authUser = localStorage.getItem('authUser');
  if (authUser !== undefined && authUser !== null) {
    authUser = JSON.parse(authUser);
    axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.token;
  }
  try {
    return await axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);        
  } catch (error) {
    return error;   
  }
}

export async function patch(url, data, config = {}) {  
  let authUser = localStorage.getItem('authUser');  
  if (authUser !== undefined && authUser !== null) {
    authUser = JSON.parse(authUser);
    axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.token;
  }
  try {
    return await axiosApi.patch(url, { ...data }, { ...config }).then((response) => response.data);        
  } catch (error) {
    return error;   
  }
}

export async function put(url, data, config = {}) {
  let authUser = localStorage.getItem('authUser');
  if (authUser !== undefined && authUser !== null) {
    authUser = JSON.parse(authUser);
    axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.token;
  }

  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  let authUser = localStorage.getItem('authUser');
  if (authUser !== undefined && authUser !== null) {
    authUser = JSON.parse(authUser);
    axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.token;
  }

  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}
