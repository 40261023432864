import {
  ADD_WATCH,
  ADD_WATCH_SUCCESSFUL,
  ADD_WATCH_FAILED,
  RESET_WATCH,
  GET_BRAND_MODELS,
  GET_BRAND_MODELS_SUCCESSFUL,
  GET_BRAND_MODELS_FAILED,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESSFUL,
  GET_INVENTORY_FAILED,
  GET_USER_INVENTORY,
  GET_USER_INVENTORY_SUCCESSFUL,
  GET_USER_INVENTORY_FAILED,
  UPDATE_USER_INVENTORY,
  UPDATE_USER_INVENTORY_SUCCESSFUL,
  UPDATE_USER_INVENTORY_FAILED,
  RESET_UPDATE_USER
} from "./actionTypes"

export const resetUpdateInventory = () => {
  return {
    type: RESET_UPDATE_USER,
  };
};

export const updateUserInventory = currentWatch => {  
  return {
    type: UPDATE_USER_INVENTORY,
    payload: currentWatch,
  };
}

export const updateUserInventorySuccessful = watch => {
  return {
    type: UPDATE_USER_INVENTORY_SUCCESSFUL,
    payload: watch,
  }
}

export const updateUserInventoryFailed = watch => {
  return {
    type: UPDATE_USER_INVENTORY_FAILED,
    payload: watch,
  }
}

export const addWatch = currentWatch => {  
  return {
    type: ADD_WATCH,
    payload: currentWatch,
  };
}

export const addWatchSuccessful = watch => {
  return {
    type: ADD_WATCH_SUCCESSFUL,
    payload: watch,
  }
}

export const addWatchFailed = watch => {
  return {
    type: ADD_WATCH_FAILED,
    payload: watch,
  }
}

export const resetWatchAlert = () => {
  return {
    type: RESET_WATCH,
  };
};

export const getUserInventory = () => {  
  return {
    type: GET_USER_INVENTORY,    
  };
}

export const getUserInventorySuccess = userInventory => {
  return {
    type: GET_USER_INVENTORY_SUCCESSFUL,
    payload: userInventory,
  }
}

export const getUserInventoryFailed = userInventory => {
  return {
    type: GET_USER_INVENTORY_FAILED,
    payload: userInventory,
  }
}

export const getBrandModels = () => {  
  return {
    type: GET_BRAND_MODELS,    
  };
}

export const getBrandModelsSuccess = brandModels => {
  return {
    type: GET_BRAND_MODELS_SUCCESSFUL,
    payload: brandModels,
  }
}

export const getBrandModelsFailed = brandModels => {
  return {
    type: GET_BRAND_MODELS_FAILED,
    payload: brandModels,
  }
}

export const getInventory = () => {  
  return {
    type: GET_INVENTORY,    
  };
}

export const getInventorySuccess = inventory => {
  return {
    type: GET_INVENTORY_SUCCESSFUL,
    payload: inventory,
  }
}

export const getInventoryFailed = inventory => {
  return {
    type: GET_INVENTORY_FAILED,
    payload: inventory,
  }
}
