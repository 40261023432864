import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { Brand, Model, Nickname, ReferenceNumber, RequestedDate, ReceivedDate, Status, Location } from "./watchlistCol";

import Breadcrumbs from "components/Common/Breadcrumb";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import { getInventory, getUserInventory, resetWatchAlert } from "../../store/actions";

const WatchList = props => {

  const navigate = useNavigate();

  document.title = "AD Watch List - Keep Track Of Luxury Watches";

  const dispatch = useDispatch();

  const isAuthenticated = () => {
    const authUser = localStorage.getItem('authUser');
    if (!authUser) return false;

    const { expire, token } = JSON.parse(authUser);
    const isExpired = new Date(expire) <= new Date();
    return token && !isExpired;
  };

  const selectWatchState = (state) => state.Watch;
  const InventoryProperties = createSelector(
    selectWatchState,
    (w) => ({
      inventory: w?.inventory || null,
      inventoryError: w?.inventoryError || null,
      inventorySuccess: w?.inventorySuccess || false,
      inventoryLoading: w?.inventoryLoading || false,
      userInventory: w?.userInventory || null,
      userInventorySuccess: w?.userInventorySuccess || false,
      userInventoryError: w?.userInventoryError || false,
    })
  );

  const {
    inventory,
    inventoryError, inventorySuccess, inventoryLoading, userInventory
  } = useSelector(InventoryProperties);

  const selectContactsState = (state) => state.contacts;
  const ContactsProperties = createSelector(
    selectContactsState,
    (Contacts) => ({
      users: Contacts.users,
      loading: Contacts.loading
    })
  );

  const {
    loading
  } = useSelector(ContactsProperties);

  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        accessor: '_id',
        show: false,
        Cell: (cellProps) => <div className="hide-column">{cellProps.value}</div>
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        filterable: true,
        Cell: (cellProps) => {          
          return (
            <div              
              onClick={() => handleEditClick(cellProps)}
            >
              <Brand {...cellProps} />
            </div>
          );
        }
      },
      {
        Header: 'Model',
        accessor: 'model',
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div              
              onClick={() => handleEditClick(cellProps)}
            >
              <Model {...cellProps} />
            </div>
          );          
        }
      },
      {
        Header: 'Location',
        accessor: 'location',
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div              
              onClick={() => handleEditClick(cellProps)}
            >
              <Location {...cellProps} />
            </div>
          );           
        }
      },
      {
        Header: 'Requested Date',
        accessor: 'requestedDateString',
        Cell: (cellProps) => {
          return (
            <div              
              onClick={() => handleEditClick(cellProps)}
            >
              <RequestedDate {...cellProps} />
            </div>
          );          
        }
      },
      {
        Header: 'Received Date',
        accessor: 'receivedDateString',
        Cell: (cellProps) => {
          return (
            <div              
              onClick={() => handleEditClick(cellProps)}
            >
              <ReceivedDate {...cellProps} />
            </div>
          );           
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div              
              onClick={() => handleEditClick(cellProps)}
            >
              <Status {...cellProps} />
            </div>
          );           
        }
      },
      {
        Header: "Action",
        Cell: cellProps => {
          // Check if the current watch is in the user's inventory          
          const isOwned = Array.isArray(userInventory) && userInventory?.includes(cellProps?.row?.original?._id) || false;
          return (
            <div className="d-flex gap-3">
              <Button
                color="link" className="btn btn-link waves-effect"
                onClick={() => handleEditClick(cellProps)}
              >
                {isOwned ? (
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                ) : (
                  <i className="mdi mdi-alert-circle-outline font-size-18" id="alerttooltip" />
                )}
                <UncontrolledTooltip placement="top" target={isOwned ? "edittooltip" : "alerttooltip"}>
                  {isOwned ? "Edit" : "View"}
                </UncontrolledTooltip>
              </Button>
            </div>
          );
        },
      }
    ],
    [inventory, userInventory]
  );

  useEffect(() => {
    const isUserAuth = isAuthenticated()
    dispatch(resetWatchAlert());
    if (isUserAuth) {
      dispatch(getUserInventory());
      dispatch(getInventory());
    } else {
      dispatch(getInventory());
    }
  }, [])

  const handleEditClick = (cellProps) => {
    if (cellProps?.row?.original) {      
      navigate("/watch-view-edit", { state: cellProps?.row?.original });
    }
  };

  const handleAddClick = () => {
    navigate("/add-watch");

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Watches" breadcrumbItem="Watch List" />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        isPagination={true}
                        columns={columns}
                        data={inventory}
                        isGlobalFilter={true}
                        isShowingPageLength={true}
                        isAddUserList={true}
                        iscustomPageSizeOptions={true}
                        handleUserClick={handleAddClick}
                        customPageSize={10}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination pagination-rounded justify-content-end mt-4"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(WatchList);
