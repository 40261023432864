import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    FormFeedback,
    Form,
    Alert,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const PrivacyPolicy = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Watches" breadcrumbItem="Terms And Conditions" />
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Col lg="10">
                                    <section className="faq-section section-b-space">
                                        <h1
                                            className="mt-0 mb-16 reveal-from-bottom"
                                            data-reveal-delay="200"
                                        >
                                            Privacy
                                            <span className="text-color-primary"> Policy</span>
                                        </h1>
                                        <div className="container-xs">
                                            <p
                                                className="m-0 mb-32 reveal-from-bottom"
                                                data-reveal-delay="400"
                                            >
                                                AD Watch List PRIVACY POLICY Last Revised: December,
                                                02 - 2023.
                                            </p>
                                            <p
                                                className="m-0 mb-32 reveal-from-bottom"
                                                data-reveal-delay="400"
                                            >
                                                Our privacy policy applies to information we collect when you
                                                use or access our website, application, or just interact with
                                                us. We may change this privacy policy from time to time.
                                                Whenever we make changes to this privacy policy, the changes are
                                                effective 30 days after we post the revised privacy policy (as
                                                indicated by revising the date at the top of our privacy
                                                policy). We encourage you to review our privacy policy whenever
                                                you access our services to stay informed about our information
                                                practices and the ways you can help protect your privacy.
                                                Collection of Information Information You Provide to Us We
                                                collect information you provide directly to us. For example, we
                                                collect information when you participate in any interactive
                                                features of our services, fill out a form, request customer
                                                support, provide any contact or identifying information or
                                                otherwise communicate with us. The types of information we may
                                                collect include your name, email address, postal address, credit
                                                card information and other contact or identifying information
                                                you choose to provide. Information We Collect Automatically When
                                                You Use the Services When you access or use our services, we
                                                automatically collect information about you, including: Log
                                                Information: We log information about your use of our services,
                                                including the type of browser you use, access times, pages
                                                viewed, your IP address and the page you visited before
                                                navigating to our services. Device Information: We collect
                                                information about the computer you use to access our services,
                                                including the hardware model, and operating system and version.
                                                Location Information: We may collect information about the
                                                location of your device each time you access or use one of our
                                                mobile applications or otherwise consent to the collection of
                                                this information. Information Collected by Cookies and Other
                                                Tracking Technologies: We use various technologies to collect
                                                information, and this may include sending cookies to your
                                                computer. Cookies are small data files stored on your hard drive
                                                or in your device memory that helps us to improve our services
                                                and your experience, see which areas and features of our
                                                services are popular and count visits. We may also collect
                                                information using web beacons (also known as "tracking pixels").
                                                Web beacons are electronic images that may be used in our
                                                services or emails and to track count visits or understand usage
                                                and campaign effectiveness. For more details about how we
                                                collect information, including details about cookies and how to
                                                disable them, please see "Your Information Choices" below.
                                                Information We Collect From Other Sources In order to provide
                                                you with access to the Service, or to provide you with better
                                                service in general, we may combine information obtained from
                                                other sources (for example, a third-party service whose
                                                application you have authorized or used to sign in) and combine
                                                that with information we collect through our services. Use of
                                                Information We use information about you for various purposes,
                                                including to: Provide, maintain and improve our services;
                                                Provide services you request, process transactions and to send
                                                you related information; Send you technical notices, updates,
                                                security alerts and support and administrative messages; Respond
                                                to your comments, questions and requests and provide customer
                                                service; Communicate with you about news and information related
                                                to our service; Monitor and analyze trends, usage and activities
                                                in connection with our services; and Personalize and improve our
                                                services. By accessing and using our services, you consent to
                                                the processing and transfer of your information in and to the
                                                United States and other countries. Sharing of Information We may
                                                share personal information about you as follows: With third
                                                party vendors and other service providers who need access to
                                                your information to carry out work on our behalf, such as Google
                                                API's, AdMob, Google Analytics; If we believe disclosure is
                                                reasonably necessary to comply with any applicable law,
                                                regulation, legal process or governmental request; To enforce
                                                applicable user agreements or policies, including our Terms of
                                                Service and to protect us, our users or the public from harm or
                                                illegal activities; In connection with any merger, sale of
                                                AD Watch List assets, financing or acquisition of
                                                all or a portion of our business to another company; and If we
                                                notify you through our services (or in our privacy policy) that
                                                the information you provide will be shared in a particular
                                                manner and you provide such information. We may also share
                                                aggregated or anonymized information that does not directly
                                                identify you. Third Party Analytics We may allow third parties
                                                to provide analytics services. These third parties may use
                                                cookies, web beacons and other technologies to collect
                                                information about your use of the services and other websites,
                                                including your IP address, web browser, pages viewed, time spent
                                                on pages, links clicked and conversion information. This
                                                information may be used by us and third parties to, among other
                                                things, analyze and track data, determine the popularity of
                                                certain content and other websites and better understand your
                                                online activity. Our privacy policy does not apply to, and we
                                                are not responsible for, third party cookies, web beacons or
                                                other tracking technologies and we encourage you to check the
                                                privacy policies of these third parties to learn more about
                                                their privacy practices. Security We take reasonable measures to
                                                help protect personal information from loss, theft, misuse and
                                                unauthorized access, disclosure, alteration and destruction.
                                                Your Information Choices Location Information When you first
                                                launch any of our mobile applications that collect location
                                                information, you will be asked to consent to the application's
                                                collection of this information. If you initially consent to our
                                                collection of location information, you can subsequently stop
                                                the collection of this information at any time by changing the
                                                preferences on your mobile device. [If you do so, our mobile
                                                applications, or certain features thereof, will no longer
                                                function.] You may also stop our collection of location
                                                information by following the standard uninstall process to
                                                remove all of our mobile applications from your device. Cookies
                                                Most web browsers are set to accept cookies by default. If you
                                                prefer, you can usually choose to set your browser to remove or
                                                reject browser cookies. Please note that if you choose to remove
                                                or reject cookies, this could affect the availability and
                                                functionality of our services. Promotional Communications You
                                                may opt out of receiving any promotional emails from us by
                                                following the instructions in those emails. If you opt out, we
                                                may still send you non-promotional communications, such as those
                                                about your account or our ongoing business relations. Your
                                                California Privacy Rights [CERTAIN TYPES OF SERVICES THAT
                                                DELIVER DIRECT MARKETING TO USERS AND INTERACT WITH CALIFORNIA
                                                RESIDENTS MAY BE SUBJECT TO TERMS IN THIS SECTION. CONSULT WITH
                                                LEGAL COUNSEL REGARDING YOUR PRIVACY OBLIGATIONS UNDER
                                                CALIFORNIA LAW.] California law permits residents of California
                                                to request certain details about how their information is shared
                                                with third parties for direct marketing purposes. If you are a
                                                California resident and would like to make such a request,
                                                please contact us at adwatchlist@gmail.com. However,
                                                please note that under the law, Services such as ours that
                                                permit California residents to opt in to, or opt out of, this
                                                type of sharing are not required to provide such information
                                                upon receiving a request, but rather may respond by notifying
                                                the user of his or her right to prevent the disclosure. To opt
                                                out of having information about you shared with third parties
                                                for direct marketing purposes, please contact us at
                                                adwatchlist@gmail.com. Contact Us If you have any
                                                questions about this privacy policy, please contact us at:
                                                adwatchlist@gmail.com [FINAL NOTE: CERTAIN APPLICATION
                                                STORES (E.G., APPLE APP STORE, GOOGLE PLAY, ETC.) MAY REQUIRE
                                                YOU TO INCLUDE CERTAIN MINIMUM PRIVACY COMMITMENTS IN THIS
                                                PRIVACY POLICY AS A CONDITION TO MAKING YOUR APPLICATION
                                                AVAILABLE ON SUCH PLATFORM. IF APPLICABLE, REVIEW YOUR AGREEMENT
                                                WITH ANY SUCH APPLICATION STORE TO DETERMINE IF ADDITIONAL
                                                PROVISIONS ARE REQUIRED.]
                                            </p>
                                        </div>
                                    </section>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PrivacyPolicy;