import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { ADD_WATCH, GET_BRAND_MODELS, GET_INVENTORY, GET_USER_INVENTORY, UPDATE_USER_INVENTORY } from "./actionTypes"
import {
  addWatchSuccessful, addWatchFailed, getBrandModelsSuccess, getBrandModelsFailed, getInventorySuccess,
  getInventoryFailed, getUserInventorySuccess, getUserInventoryFailed, updateUserInventoryFailed, updateUserInventorySuccessful
} from "./actions"

import { addWatch, getBrandModels, getInventory, getUserInventory, updateUserInventory } from '../../helpers/backend_helper';

function* updateUserWatch(currentWatch) {
  try {
    const cWatch = currentWatch?.payload
    const resp = yield call(updateUserInventory, cWatch)
    if (resp?.response?.data?.code === 201 || resp === "Watch updated") {
      yield put(updateUserInventorySuccessful(resp))
    } else {
      yield put(updateUserInventoryFailed(resp))
    }
  } catch (error) {
    yield put(updateUserInventoryFailed(error))
  }
}

function* retrieveUserInventary() {
  try {
    const resp = yield call(getUserInventory)
    yield put(getUserInventorySuccess(resp))
  } catch (error) {
    yield put(getUserInventoryFailed(error))
  }
}

function* retrieveInventory() {
  try {
    const resp = yield call(getInventory)
    yield put(getInventorySuccess(resp))
  } catch (error) {
    yield put(getInventoryFailed(error))
  }
}

function* retrieveBrandModels() {
  try {
    const resp = yield call(getBrandModels)
    yield put(getBrandModelsSuccess(resp))
  } catch (error) {
    yield put(getBrandModelsFailed(error))
  }
}

function* createWatch(currentWatch) {
  try {
    const cWatch = currentWatch?.payload
    const resp = yield call(addWatch, cWatch)
    if (resp?.response?.data?.code === 201 || resp === "Watch added") {
      yield put(addWatchSuccessful(resp))
    } else {
      yield put(addWatchFailed(resp))
    }
  } catch (error) {
    yield put(addWatchFailed(error))
  }
}

export function* watchAddWatch() {
  yield takeEvery(UPDATE_USER_INVENTORY, updateUserWatch)
  yield takeEvery(GET_USER_INVENTORY, retrieveUserInventary)
  yield takeEvery(GET_INVENTORY, retrieveInventory)
  yield takeEvery(GET_BRAND_MODELS, retrieveBrandModels)
  yield takeEvery(ADD_WATCH, createWatch)
}

function* watchSaga() {
  yield all([fork(watchAddWatch)])
}

export default watchSaga
