import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    FormFeedback,
    Form,
    Alert,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const TermsAndConditions = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Watches" breadcrumbItem="Terms And Conditions" />
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Col lg="10">
                                    <section className="faq-section section-b-space">
                                        <p>AD Watch List Terms and Conditions </p>
                                        <p>Terms and Conditions ("Terms")</p>
                                        <p>Last updated: Dec 01 2023</p>
                                        <hr />
                                        <p>
                                            Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the website {' '}
                                            adwatchlist.com operated by AD Watch List ("us", "we", or "our").
                                        </p>
                                        <p>
                                            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
                                            These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the
                                            Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not
                                            access the Service.{' '}
                                        </p>
                                        <hr />
                                        <p>Pricing</p>
                                        <p>
                                            We do not guarantee the price for the product displayed in our mobile application or website.  The prices
                                            shown at our mobile application or website might vary between the time you have seen the product in our
                                            website or mobile application, and the time that you have visited the Seller application(website or other).
                                            Should you have any questions on pricing, please contact the Seller directly.
                                        </p>
                                        <hr />
                                        <p>Links To Other Web Sites</p>
                                        <p>
                                            Our Service may contain links to third-party web sites or services that are not owned or controlled by the
                                            us. We have no control over, and assumes no responsibility for, the content, privacy policies, or practices
                                            of any third party web sites or services. You further acknowledge and agree that AD Watch List shall not be
                                            responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or
                                            in connection with use of or reliance on any such content, goods or services available on or through any
                                            such web sites or services.
                                        </p>
                                        <hr />
                                        <p>Proprietary Rights</p>
                                        <p>
                                            All rights, title, interest in and to the Service are and will remain exclusive property of AD Watch List.  You are not authorized to copy or redistribute the contents found on AD Watch List mobile
                                            application or website unless you have a written permission. We will respond to notices of alleged copyright
                                            infringement that comply with Applicable law and are properly provided to us.  Should you have any questions
                                            or believe that your Content has been copied in a way that constitutes copyright Infringement, please
                                            provide our copyright agent with the following information Accordance with the Digital Millennium Copyright
                                            Act:
                                            <ul>
                                                <li>
                                                    • A physical or electronic signature of the copyright owner or a person authorized to act on their
                                                    behalf,{' '}
                                                </li>
                                                <li>• Identification of the copyrighted work claimed to have been infringed, </li>
                                                <li>
                                                    • Identification of the material that is claimed to be infringing or to be the subject of infringing
                                                    activity and that is to be removed or access to which is to be disabled, and information reasonably
                                                    sufficient to permit us to locate the material,{' '}
                                                </li>
                                                <li>• Your contact information, including your address, telephone number, and email address, </li>
                                                <li>
                                                    • A statement by you that you have a good faith belief that use of the material in the manner complained
                                                    of is not authorized by the copyright owner, its agent, or the law, and{' '}
                                                </li>
                                                <li>
                                                    • A statement that the information in the notification is accurate, and, under penalty of perjury, that
                                                    you are authorized to act on behalf of the copyright owner.
                                                </li>
                                            </ul>{' '}
                                            Should you need inquiries on proprietary rights, please contact us at adwatchlist@gmail.com
                                            <hr />
                                            <p>Copyright Policy</p>
                                            <p>
                                                AD Watch List respects the intellectual property rights of others and expect users to the same. You may not
                                                retrieve information from our website or mobile application in any form without proper authorization.
                                            </p>
                                            <hr />
                                            <p>Changes</p>
                                            <p>
                                                Changes We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                                                revision is material we will try to provide at least 30 days notice prior to any new terms taking effect.
                                                What constitutes a material change will be determined at our sole discretion.{' '}
                                            </p>
                                            <hr />
                                            <p>Contact Us</p>
                                            <p>If you have any questions about these Terms, please contact us at adwatchlist@gmail.com</p>
                                        </p>
                                    </section>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TermsAndConditions;