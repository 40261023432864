export const ADD_WATCH = "add_watch"
export const ADD_WATCH_SUCCESSFUL = "add_watch_successfull"
export const ADD_WATCH_FAILED = "add_watch_failed"
export const RESET_WATCH = "reset_watch"

export const GET_BRAND_MODELS = "get_brand_models"
export const GET_BRAND_MODELS_SUCCESSFUL = "get_brand_models_successful"
export const GET_BRAND_MODELS_FAILED = "get_brand_models_failed"

export const GET_INVENTORY = "get_inventory"
export const GET_INVENTORY_SUCCESSFUL = "get_inventory_successful"
export const GET_INVENTORY_FAILED = "get_inventory_failed"

export const GET_USER_INVENTORY = "get_user_inventory"
export const GET_USER_INVENTORY_SUCCESSFUL = "get_user_inventory_successful"
export const GET_USER_INVENTORY_FAILED = "get_user_inventory_failed"

export const UPDATE_USER_INVENTORY = "update_user_inventory"
export const UPDATE_USER_INVENTORY_SUCCESSFUL = "update_user_inventory_successful"
export const UPDATE_USER_INVENTORY_FAILED = "update_user_inventory_failed"
export const RESET_UPDATE_USER = "reset_update_user"



