import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Watch create
import WatchesCreate from "../pages/WatchAdd/watches-create"
import WatchList from "../pages/WatchList/watch-list"
import WatchViewEdit from "../pages/WatchViewEdit/watches-view-edit"

// Terms and Conditions
import TermsAndConditions from "../pages/TermsAndConditions/terms-and-conditions";

// Privacy Policy
import PrivacyPolicy from "../pages/PrivacyPolicy/privacy-policy";

const isAuthenticated = () => {
  const authUser = localStorage.getItem('authUser');
  if (!authUser) return false;

  const { expire, token } = JSON.parse(authUser);
  const isExpired = new Date(expire) <= new Date();
  return token && !isExpired;
};

const ProtectedComponent = ({ component: Component }) => {
  return isAuthenticated() ? Component : <Navigate to="/login" />;
};

const authProtectedRoutes = [
  { path: "/main", component: <WatchList /> },
  {
    path: "/add-watch",
    component: <ProtectedComponent component={<WatchesCreate />} />
  },
  { path: "/watch-view-edit", component: <WatchViewEdit /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/main" />,
  },
  { path: "*", element: <Navigate to="/" replace /> }
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/terms-and-conditions", component: <TermsAndConditions /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "*", element: <Navigate to="/" replace /> },
];

export { authProtectedRoutes, publicRoutes };
