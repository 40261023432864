import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";
import { createSelector } from "reselect";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Filter from 'bad-words';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetCity,
  GetState
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

import { addWatch, addWatchFailed, resetWatchAlert, getBrandModels, getBrandModelsFailed } from "../../store/actions";

const WatchesCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  document.title = "Add Watch | ADWatchList";

  const badWordFilter = new Filter();

  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [citiesCount, setCitiesCount] = useState(0);
  const [stateCount, setStateCount] = useState(0);

  useEffect(() => {
    dispatch(addWatchFailed(""));
    dispatch(getBrandModels())
  }, []);

  const validation = useFormik({
    initialValues: {
      brand: '',
      model: '',
      nickname: '',
      referenceNumber: '',
      countryid: '',
      stateid: '',
      cityid: '',
      country: '',
      state: '',
      city: '',
      requestedDate: new Date(),
      status: 'Waiting',
      receivedDate: null,
    },
    validationSchema: Yup.object({
      brand: Yup.string().required("Please Enter Brand").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      model: Yup.string().required("Please Enter Model").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      nickname: Yup.string().optional("Please Enter Nickname").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      referenceNumber: Yup.string().optional("Please Enter Reference Number").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      requestedDate: Yup.date().required("Please Enter Requested Date"),
      status: Yup.string().default("waiting").required("Please Enter Status"),
      receivedDate: Yup.date().nullable().notRequired()
    }),
    onSubmit: (values) => {
      dispatch(addWatch(values));
    },
  });

  const selectWatchState = (state) => state.Watch;
  const WatchProperties = createSelector(
    selectWatchState,
    (w) => ({
      watch: w?.watch || null,
      addWatchError: w?.addWatchError || null,
      success: w?.success || false,
      brandModels: w?.brandModels || null,
      brandModelsSuccess: w?.brandModelsSuccess || false,
      brandModelsError: w?.brandModelsError || null,
    })
  );

  const {
    watch,
    addWatchError, success, brandModels, brandModelsSuccess, brandModelsError
  } = useSelector(WatchProperties);

  useEffect(() => {
    success && dispatch(resetWatchAlert()) && setTimeout(() => navigate("/main"), 500)
  }, [success])

  useEffect(() => {
    if (addWatchError?.response?.data?.code === 401) {
      dispatch(resetWatchAlert())
      navigate("/login")
    }
  }, [addWatchError])

  useEffect(() => {
    if (validation.values.status === "Received" && !validation.values.receivedDate) {
      validation.setFieldValue("receivedDate", new Date());
    } else if (validation.values.status === "Waiting") {
      validation.setFieldValue("receivedDate", null);
    }
  }, [validation.values.status, validation.setFieldValue, validation.values.receivedDate]);

  // Update this function to handle input changes for brand and display suggestions
  const handleBrandInputChange = (event) => {
    dispatch(resetWatchAlert());
    const value = event.target.value;
    validation.handleChange(event); // keep Formik's handler

    // Filter brand models based on user input
    if (value && brandModels && brandModels.length > 0) {
      const filteredBrands = brandModels.filter(brandModel =>
        brandModel.brand.toLowerCase().includes(value.toLowerCase())
      ).map(filteredBrandModel => filteredBrandModel.brand);
      setSuggestions(filteredBrands);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (brand) => {
    validation.setFieldValue('brand', brand);
    setSuggestions([]);

    // Reset model field and update model suggestions
    validation.setFieldValue('model', ''); // Reset model field
    updateModelSuggestions(brand); // Update model suggestions for the new brand
  };

  const updateModelSuggestions = (selectedBrand) => {
    if (selectedBrand && brandModels) {
      const selectedBrandModels = brandModels.find(bm => bm.brand === selectedBrand)?.models || [];
      setModelSuggestions(selectedBrandModels);
    } else {
      setModelSuggestions([]);
    }
  };

  // Function to handle date changes
  const handleDateChange = (name, date) => {
    validation.setFieldValue(name, date[0]);
  };

  const handleModelInputChange = (event) => {
    const value = event.target.value;
    validation.handleChange(event); // Keep Formik's handler

    if (value && brandModels.length > 0) {
      // If user starts typing, find the selected brand's models
      const selectedBrandModels = brandModels.find(bm => bm.brand === validation.values.brand)?.models || [];
      const filteredModels = selectedBrandModels.filter(model =>
        model.toLowerCase().includes(value.toLowerCase())
      );
      setModelSuggestions(filteredModels);
    } else {
      setModelSuggestions([]);
    }
  };

  const handleModelSuggestionClick = (model) => {
    validation.setFieldValue('model', model);
    setModelSuggestions([]);
  };

  const getStates = async (countryid) => {
    try {
      setCitiesCount(0);
      setStateCount(0);
      const states = await GetState(countryid);
      setStateCount(states.length);
    } catch (error) {
      setStateCount(0);
    }
  }

  const getCities = async (countryid, stateid) => {
    try {
      const cities = await GetCity(countryid, stateid);
      setCitiesCount(cities.length);
    } catch (error) {
      setCitiesCount(0);
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Watches" breadcrumbItem="Add Watch" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">New Watch</CardTitle>
                <Form onSubmit={validation.handleSubmit}>
                  {watch && watch ? (
                    <Alert color="success">
                      Watch Successfully Added
                    </Alert>
                  ) : null}

                  {addWatchError && addWatchError ? (
                    <Alert color="danger">Could not add watch. Make sure that Brand, Model, Location are added.</Alert>
                  ) : null}
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="brand" className="col-form-label col-lg-2">
                      Brand
                    </Label>
                    <Col lg="10">
                      <Input
                        id="brand"
                        name="brand"
                        className="form-control"
                        placeholder="Enter brand"
                        type="text"
                        autoComplete="off"
                        onChange={handleBrandInputChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.brand || ""}
                        invalid={
                          validation.touched.brand && validation.errors.brand ? true : false
                        }
                      />
                      {suggestions.length > 0 && (
                        <div className="autocomplete-suggestions">
                          {suggestions.map((suggestion, index) => (
                            <div
                              key={index}
                              className="suggestion-item"
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </div>
                          ))}
                        </div>
                      )}
                      {validation.touched.brand && validation.errors.brand && (
                        <FormFeedback type="invalid">
                          {validation.errors.brand}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="model"
                      className="col-form-label col-lg-2"
                    >
                      Model
                    </Label>
                    <Col lg="10">
                      <Input
                        autoComplete="off"
                        id="model"
                        name="model"
                        className="form-control"
                        placeholder="Enter model"
                        type="model"
                        onChange={handleModelInputChange}
                        value={validation.values.model || ""}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.model && validation.errors.model ? true : false
                        }
                      />
                      {modelSuggestions.length > 0 && validation.values.model && (
                        <div className="autocomplete-suggestions">
                          {modelSuggestions.map((suggestion, index) => (
                            <div
                              key={index}
                              className="suggestion-item"
                              onClick={() => handleModelSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </div>
                          ))}
                        </div>
                      )}
                      {validation.touched.model && validation.errors.model ? (
                        <FormFeedback type="invalid">{validation.errors.model}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="country"
                      className="col-form-label col-lg-2"
                    >
                      Country
                    </Label>
                    <Col lg="10">
                      <CountrySelect
                        id="country"
                        name="country"
                        type="country"
                        onChange={(e) => validation.setFieldValue('countryid', e?.id) && validation.setFieldValue('country', e?.name) && getStates(e?.id)}
                        onBlur={validation.handleBlur}
                      />
                    </Col>
                  </FormGroup>

                  {stateCount > 0 && (
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        State
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          id="state"
                          name="state"
                          className="form-control"
                          type="state"
                          countryid={validation.values.countryid}
                          onChange={(e) => validation.setFieldValue('stateid', e?.id) && validation.setFieldValue('state', e?.name) && getCities(validation.values.countryid, e?.id)}
                          onBlur={validation.handleBlur}
                        />
                      </Col>
                    </FormGroup>
                  )}

                  {citiesCount > 0 && (
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="city"
                        className="col-form-label col-lg-2"
                      >
                        City
                      </Label>
                      <Col lg="10">
                        <CitySelect
                          id="city"
                          name="city"
                          className="form-control"
                          type="city"
                          countryid={validation.values.countryid}
                          stateid={validation.values.stateid}
                          onChange={(e) => validation.setFieldValue('cityid', e?.id) && validation.setFieldValue('city', e?.name)}
                          onBlur={validation.handleBlur}
                        />
                      </Col>
                    </FormGroup>
                  )}

                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="referencenumber"
                      className="col-form-label col-lg-2"
                    >
                      Reference #
                    </Label>
                    <Col lg="10">
                      <Input
                        autoComplete="off"
                        id="referenceNumber"
                        name="referenceNumber"
                        className="form-control"
                        placeholder="Enter Reference # (ABDCD1, Don't know, etc...)"
                        type="referenceNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.referenceNumber || ""}
                        invalid={
                          validation.touched.referenceNumber && validation.errors.referenceNumber ? true : false
                        }
                      />
                      {validation.touched.referenceNumber && validation.errors.referenceNumber ? (
                        <FormFeedback type="invalid">{validation.errors.referenceNumber}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>

                  {/* Requested Date Picker */}
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">
                      Requested Date
                    </Label>
                    <Col lg="10">
                      <FlatPickr
                        className="form-control"
                        name="requestedDate"
                        options={{ dateFormat: "d M,Y" }}
                        value={validation.values.requestedDate}
                        onChange={(date) => handleDateChange("requestedDate", date)}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() => validation.setFieldValue("requestedDate", '')}
                      >
                        Clear Date
                      </button>
                    </Col>
                  </FormGroup>

                  {/* Status Dropdown */}
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="status" className="col-form-label col-lg-2">
                      Status
                    </Label>
                    <Col lg="10">
                      <select
                        className="form-control"
                        name="status"
                        value={validation.values.status}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      >
                        <option value="Waiting">Waiting</option>
                        <option value="Received">Received</option>
                      </select>
                    </Col>
                  </FormGroup>

                  {/* Received Date Picker */}
                  {validation.values.status === "Received" && (
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Received Date
                      </Label>
                      <Col lg="10">
                        <FlatPickr
                          className="form-control"
                          name="receivedDate"
                          options={{ dateFormat: "d M,Y" }}
                          value={validation.values.receivedDate}
                          onChange={(date) => handleDateChange("receivedDate", date)}
                        />
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => validation.setFieldValue("receivedDate", '')}
                        >
                          Clear Date
                        </button>
                      </Col>
                    </FormGroup>
                  )}
                  {/* Submit Button */}
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <button className="btn btn-primary btn-block" type="submit">
                        Add Watch
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default WatchesCreate;
