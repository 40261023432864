import {
  ADD_WATCH,
  ADD_WATCH_SUCCESSFUL,
  ADD_WATCH_FAILED,
  RESET_WATCH,
  GET_BRAND_MODELS,
  GET_BRAND_MODELS_SUCCESSFUL,
  GET_BRAND_MODELS_FAILED,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESSFUL,
  GET_INVENTORY_FAILED,
  GET_USER_INVENTORY,
  GET_USER_INVENTORY_SUCCESSFUL,
  GET_USER_INVENTORY_FAILED,
  UPDATE_USER_INVENTORY,
  UPDATE_USER_INVENTORY_SUCCESSFUL,
  UPDATE_USER_INVENTORY_FAILED,
  RESET_UPDATE_USER,
} from "./actionTypes"

const initialState = {
  addWatchError: null,
  message: null,
  loading: false,
  watch: null,
  success: false,
  brandModels: null,
  brandModelsError: null,
  brandModelsLoading: false,
  brandModelsSuccess: false,
  inventory: null,
  inventoryError: null,
  inventoryLoading: false,
  inventorySuccess: false,
  userInventory: null,
  userInventoryError: null,
  userInventoryLoading: false,
  userInventorySuccess: false,
  updateUserInventory: null,
  updateUserInventoryError: null,
  updateUserInventoryLoading: false,
  updateUserInventorySuccess: false,
}

const watch = (state = initialState, action) => {
  switch (action.type) {
    case RESET_UPDATE_USER:
      return {
        ...state,
        updateUserInventory: null,
        updateUserInventoryError: null,
        updateUserInventoryLoading: false,
        updateUserInventorySuccess: false,
      };
    case UPDATE_USER_INVENTORY_FAILED:
      state = {
        ...state,
        updateUserInventory: null,
        updateUserInventoryLoading: false,
        updateUserInventorySuccess: false,
        updateUserInventoryError: action.payload,
      }
      break
    case UPDATE_USER_INVENTORY_SUCCESSFUL:
      state = {
        ...state,
        updateUserInventoryLoading: false,
        updateUserInventory: action.payload,
        updateUserInventorySuccess: true,
        updateUserInventoryError: null,
      }
      break
    case UPDATE_USER_INVENTORY:
      state = {
        ...state,
        updateUserInventoryLoading: true,
        updateUserInventorySuccess: false,
        updateUserInventoryError: null,
      }
      break
    case GET_USER_INVENTORY:
      state = {
        ...state,
        userInventoryLoading: true,
        userInventorySuccess: false,
        userInventoryError: null,
      }
      break
    case GET_USER_INVENTORY_SUCCESSFUL:
      state = {
        ...state,
        userInventoryLoading: false,
        userInventory: action.payload,
        userInventorySuccess: true,
        userInventoryError: null,
      }
      break
    case GET_USER_INVENTORY_FAILED:
      state = {
        ...state,
        userInventory: null,
        userInventoryLoading: false,
        userInventorySuccess: false,
        userInventoryError: action.payload,
      }
      break
    case GET_INVENTORY:
      state = {
        ...state,
        inventoryLoading: true,
        inventorySuccess: false,
        inventoryError: null,
      }
      break
    case GET_INVENTORY_SUCCESSFUL:
      state = {
        ...state,
        inventoryLoading: false,
        inventory: action.payload,
        inventorySuccess: true,
        inventoryError: null,
      }
      break
    case GET_INVENTORY_FAILED:
      state = {
        ...state,
        inventory: null,
        inventoryLoading: false,
        inventorySuccess: false,
        inventoryError: action.payload,
      }
      break
    case GET_BRAND_MODELS:
      state = {
        ...state,
        brandModelsLoading: true,
        brandModelsSuccess: false,
        brandModelsError: null,
      }
      break
    case GET_BRAND_MODELS_SUCCESSFUL:
      state = {
        ...state,
        brandModelsLoading: false,
        brandModels: action.payload,
        brandModelsSuccess: true,
        brandModelsError: null,
      }
      break
    case GET_BRAND_MODELS_FAILED:
      state = {
        ...state,
        brandModels: null,
        brandModelsLoading: false,
        brandModelsSuccess: false,
        brandModelsError: action.payload,
      }
      break
    case ADD_WATCH:
      state = {
        ...state,
        loading: true,
        success: false,
        addWatchError: null,
      }
      break
    case ADD_WATCH_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        watch: action.payload,
        success: true,
        addWatchError: null,
      }
      break
    case ADD_WATCH_FAILED:
      state = {
        ...state,
        watch: null,
        loading: false,
        success: false,
        addWatchError: action.payload,
      }
      break
    case RESET_WATCH:      
      return {
        ...state,
        watch: null,
        addWatchError: null,
        success: false,
        message: null,
        loading: false,
        userInventory: null,
        userInventoryError: null,
        userInventoryLoading: false,
        userInventorySuccess: false,
      };
    default:
      state = { ...state }
      break
  }
  return state
}

export default watch
