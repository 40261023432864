import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";
import { createSelector } from "reselect";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Filter from 'bad-words';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetCountries,
  GetCity,
  GetState
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

import { getBrandModels, getUserInventory, updateUserInventory, updateUserInventoryFailed, resetUpdateInventory } from "../../store/actions";

const WatchViewEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const passedData = location.state;
  document.title = "watch-view-edit | ADWatchList";

  const badWordFilter = new Filter();

  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showReceivedDate, setShowReceivedDate] = useState(false);
  const [citiesCount, setCitiesCount] = useState(0);
  const [stateCount, setStateCount] = useState(0);

  useEffect(() => {
    dispatch(updateUserInventoryFailed(""));
    dispatch(getBrandModels());
    dispatch(getUserInventory());
  }, []);

  const validation = useFormik({
    initialValues: {
      brand: passedData?.brand || '',
      model: passedData?.model || '',
      countryid: '',
      stateid: '',
      cityid: '',
      country: '',
      state: '',
      city: '',
      countryDefault: '',
      stateDefault: '',
      cityDefault: '',
      referenceNumber: passedData?.referenceNumber || '',
      requestedDate: passedData?.requestedDate || new Date(),
      status: passedData?.status || 'Waiting',
      receivedDate: passedData?.receivedDate && passedData.receivedDate !== "0001-01-01T00:00:00Z" ? new Date(passedData.receivedDate) : new Date(),
    },
    validationSchema: Yup.object({
      brand: Yup.string().required("Please Enter Brand").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      model: Yup.string().required("Please Enter Model").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      referenceNumber: Yup.string().optional("Please Enter Reference Number").test(
        'no-bad-words',
        'No bad words allowed!',
        value => !badWordFilter.isProfane(value)
      ),
      requestedDate: Yup.date().required("Please Enter Requested Date"),
      status: Yup.string().default("waiting").required("Please Enter Status"),
      receivedDate: Yup.date().nullable().notRequired()
    }),
    onSubmit: (values) => {
      if (isWatchOwned) {
        const { countryDefault, stateDefault, cityDefault, countryid, stateid, cityid, ...rest } = values;
        const updatedValues = {
          ...rest,
          _id: passedData?._id
        };
        dispatch(updateUserInventory(updatedValues));
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (passedData?.country) {
        const country = (await GetCountries()).find(c => c.name === passedData.country);
        validation.setFieldValue('countryid', country?.id);
        validation.setFieldValue('country', country?.name);
        validation.setFieldValue('countryDefault', country);
        if (passedData?.state) {
          const state = (await GetState(country?.id)).find(s => s.name === passedData.state);
          validation.setFieldValue('stateid', state?.id);
          validation.setFieldValue('state', state?.name);
          validation.setFieldValue('stateDefault', state);
          setStateCount(Object.keys(state).length);
          if (passedData?.city) {
            const city = (await GetCity(country?.id, state?.id)).find(c => c.name === passedData.city);
            validation.setFieldValue('cityid', city?.id);
            validation.setFieldValue('city', city?.name);
            validation.setFieldValue('cityDefault', city);
            setCitiesCount(Object.keys(city).length);
          }
        }
      }
    };

    fetchData();
  }, [passedData])

  useEffect(() => {
    // Set the visibility of the Received Date field based on the status
    setShowReceivedDate(validation.values.status === "Received");

    // Set default Received Date to today if status is "Received" and receivedDate is not set
    if (validation.values.status === "Received" && !validation.values.receivedDate) {
      const today = new Date();
      validation.setFieldValue("receivedDate", today);
    }
  }, [validation.values.status, validation.values.receivedDate, validation.setFieldValue]);

  const selectWatchState = (state) => state.Watch;
  const WatchProperties = createSelector(
    selectWatchState,
    (w) => ({
      watch: w?.watch || null,
      brandModels: w?.brandModels || null,
      brandModelsSuccess: w?.brandModelsSuccess || false,
      brandModelsError: w?.brandModelsError || null,
      userInventory: w?.userInventory || null,
      userInventorySuccess: w?.userInventorySuccess || false,
      userInventoryError: w?.userInventoryError || false,
      updateUserSuccess: w?.updateUserInventorySuccess || false,
      updateUserInventoryError: w?.updateUserInventoryError || false,
      updateUserInventoryLoading: w?.updateUserInventoryLoading || false,
    })
  );

  const {
    watch, brandModels, userInventory, updateUserSuccess, updateUserInventoryError
  } = useSelector(WatchProperties);

  useEffect(() => {
    updateUserSuccess && dispatch(resetUpdateInventory()) && setTimeout(() => navigate("/main"), 500)
  }, [updateUserSuccess])

  useEffect(() => {
    if (updateUserInventoryError?.response?.data?.code === 401) {
      dispatch(resetUpdateInventory())
      navigate("/login")
    }
  }, [updateUserInventoryError])

  // Update this function to handle input changes for brand and display suggestions
  const handleBrandInputChange = (event) => {
    dispatch(resetUpdateInventory());
    const value = event.target.value;
    validation.handleChange(event); // keep Formik's handler

    // Filter brand models based on user input
    if (value && brandModels && brandModels.length > 0) {
      const filteredBrands = brandModels.filter(brandModel =>
        brandModel.brand.toLowerCase().includes(value.toLowerCase())
      ).map(filteredBrandModel => filteredBrandModel.brand);
      setSuggestions(filteredBrands);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (brand) => {
    validation.setFieldValue('brand', brand);
    setSuggestions([]);

    // Reset model field and update model suggestions
    validation.setFieldValue('model', ''); // Reset model field
    updateModelSuggestions(brand); // Update model suggestions for the new brand
  };

  const updateModelSuggestions = (selectedBrand) => {
    if (selectedBrand && brandModels) {
      const selectedBrandModels = brandModels.find(bm => bm.brand === selectedBrand)?.models || [];
      setModelSuggestions(selectedBrandModels);
    } else {
      setModelSuggestions([]);
    }
  };

  // Function to handle date changes
  const handleDateChange = (name, date) => {
    validation.setFieldValue(name, date[0]);
  };

  const handleModelInputChange = (event) => {
    const value = event.target.value;
    validation.handleChange(event); // Keep Formik's handler

    if (value && brandModels.length > 0) {
      // If user starts typing, find the selected brand's models
      const selectedBrandModels = brandModels.find(bm => bm.brand === validation.values.brand)?.models || [];
      const filteredModels = selectedBrandModels.filter(model =>
        model.toLowerCase().includes(value.toLowerCase())
      );
      setModelSuggestions(filteredModels);
    } else {
      setModelSuggestions([]);
    }
  };

  const handleModelSuggestionClick = (model) => {
    validation.setFieldValue('model', model);
    setModelSuggestions([]);
  };

  const isWatchOwned = userInventory && userInventory.length > 0 && userInventory.includes(passedData?._id);

  const getStates = async (countryid) => {
    try {
      validation.setFieldValue('stateid', '');
      validation.setFieldValue('state', '');
      validation.setFieldValue('stateDefault', '');
      validation.setFieldValue('cityid', '');
      validation.setFieldValue('city', '');
      validation.setFieldValue('cityDefault', '');
      setCitiesCount(0);
      setStateCount(0);
      const states = await GetState(countryid);
      setStateCount(states.length);
    } catch (error) {
      setStateCount(0);
    }
  }

  const getCities = async (countryid, stateid) => {
    try {
      const cities = await GetCity(countryid, stateid);
      setCitiesCount(cities.length);
    } catch (error) {
      setCitiesCount(0);
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Watches" breadcrumbItem="Watch View Edit" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">New Watch</CardTitle>
                <Form onSubmit={validation.handleSubmit}>
                  {watch && watch ? (
                    <Alert color="success">
                      Watch Successfully Added
                    </Alert>
                  ) : null}

                  {updateUserInventoryError && updateUserInventoryError ? (
                    <Alert color="danger">Could not add watch. Make sure that Brand, Model, Location are added.</Alert>
                  ) : null}
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="brand" className="col-form-label col-lg-2">
                      Brand
                    </Label>
                    <Col lg="10">
                      <Input
                        id="brand"
                        name="brand"
                        className="form-control"
                        placeholder="Enter brand"
                        type="text"
                        autoComplete="off"
                        disabled={!isWatchOwned}
                        onChange={handleBrandInputChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.brand || ""}
                        invalid={
                          validation.touched.brand && validation.errors.brand ? true : false
                        }
                      />
                      {suggestions.length > 0 && (
                        <div className="autocomplete-suggestions">
                          {suggestions.map((suggestion, index) => (
                            <div
                              key={index}
                              className="suggestion-item"
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </div>
                          ))}
                        </div>
                      )}
                      {validation.touched.brand && validation.errors.brand && (
                        <FormFeedback type="invalid">
                          {validation.errors.brand}
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="model"
                      className="col-form-label col-lg-2"
                    >
                      Model
                    </Label>
                    <Col lg="10">
                      <Input
                        autoComplete="off"
                        id="model"
                        name="model"
                        className="form-control"
                        placeholder="Enter model"
                        type="model"
                        disabled={!isWatchOwned}
                        onChange={handleModelInputChange}
                        value={validation.values.model || ""}
                        onBlur={validation.handleBlur}                        
                        invalid={
                          validation.touched.model && validation.errors.model ? true : false
                        }
                      />
                      {modelSuggestions.length > 0 && validation.values.model && (
                        <div className="autocomplete-suggestions">
                          {modelSuggestions.map((suggestion, index) => (
                            <div
                              key={index}
                              className="suggestion-item"
                              onClick={() => handleModelSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </div>
                          ))}
                        </div>
                      )}
                      {validation.touched.model && validation.errors.model ? (
                        <FormFeedback type="invalid">{validation.errors.model}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>

                  {isWatchOwned ?
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="country"
                        className="col-form-label col-lg-2"
                      >
                        Country
                      </Label>
                      <Col lg="10">
                        <CountrySelect
                          id="country"
                          name="country"
                          type="country"
                          defaultValue={validation?.values?.countryDefault || ""}
                          onChange={(e) => validation.setFieldValue('countryid', e?.id) && validation.setFieldValue('country', e?.name) && getStates(e?.id)}
                          onBlur={validation.handleBlur}
                        />
                      </Col>
                    </FormGroup> : <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="country"
                        className="col-form-label col-lg-2"
                      >
                        Country
                      </Label>
                      <Col lg="10">
                        <Input
                          autoComplete="off"
                          id="country"
                          name="country"
                          className="form-control"
                          placeholder={passedData?.country || ""}
                          type="country"
                          disabled={!isWatchOwned}
                        />
                      </Col>
                    </FormGroup>}

                  {isWatchOwned && stateCount > 0 ?
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        State
                      </Label>
                      <Col lg="10">
                        <StateSelect
                          id="state"
                          name="state"
                          className="form-control"
                          type="state"
                          defaultValue={validation?.values?.stateDefault || ""}
                          countryid={validation.values.countryid}
                          onChange={(e) => validation.setFieldValue('stateid', e?.id) && validation.setFieldValue('state', e?.name) && getCities(validation.values.countryid, e?.id)}
                          onBlur={validation.handleBlur}
                        />
                      </Col>
                    </FormGroup>
                    : <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="state"
                        className="col-form-label col-lg-2"
                      >
                        State
                      </Label>
                      <Col lg="10">
                        <Input
                          autoComplete="off"
                          id="state"
                          name="state"
                          className="form-control"
                          placeholder={passedData?.state || ""}
                          type="state"
                          disabled={!isWatchOwned}
                        />
                      </Col>
                    </FormGroup>}

                  {isWatchOwned && citiesCount > 0 ?
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="city"
                        className="col-form-label col-lg-2"
                      >
                        City
                      </Label>
                      <Col lg="10">
                        <CitySelect
                          id="city"
                          name="city"
                          className="form-control"
                          type="city"
                          defaultValue={validation?.values?.cityDefault || ""}
                          countryid={validation.values.countryid}
                          stateid={validation.values.stateid}
                          onChange={(e) => validation.setFieldValue('cityid', e?.id) && validation.setFieldValue('city', e?.name)}
                          onBlur={validation.handleBlur}
                        />
                      </Col>
                    </FormGroup>
                    : <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="city"
                        className="col-form-label col-lg-2"
                      >
                        City
                      </Label>
                      <Col lg="10">
                        <Input
                          autoComplete="off"
                          id="city"
                          name="city"
                          className="form-control"
                          placeholder={passedData?.city || ""}
                          type="city"
                          disabled={!isWatchOwned}
                        />
                      </Col>
                    </FormGroup>}

                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="referencenumber"
                      className="col-form-label col-lg-2"
                    >
                      Reference #
                    </Label>
                    <Col lg="10">
                      <Input
                        autoComplete="off"
                        id="referenceNumber"
                        name="referenceNumber"
                        className="form-control"
                        placeholder="Enter Reference # (ABDCD1, Don't know, etc...)"
                        type="referenceNumber"
                        disabled={!isWatchOwned}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.referenceNumber || ""}
                        invalid={
                          validation.touched.referenceNumber && validation.errors.referenceNumber ? true : false
                        }
                      />
                      {validation.touched.referenceNumber && validation.errors.referenceNumber ? (
                        <FormFeedback type="invalid">{validation.errors.referenceNumber}</FormFeedback>
                      ) : null}
                    </Col>
                  </FormGroup>

                  {/* Requested Date Picker */}
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">
                      Requested Date
                    </Label>
                    <Col lg="10">
                      <FlatPickr
                        className="form-control"
                        name="requestedDate"
                        options={{ dateFormat: "d M,Y" }}
                        value={validation.values.requestedDate}
                        onChange={(date) => handleDateChange("requestedDate", date)}
                        disabled={!isWatchOwned}
                      />
                      {isWatchOwned && (
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => validation.setFieldValue("requestedDate", '')}
                        >
                          Clear Date
                        </button>)}
                    </Col>
                  </FormGroup>

                  {/* Status Dropdown */}
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="status" className="col-form-label col-lg-2">
                      Status
                    </Label>
                    <Col lg="10">
                      <select
                        className="form-control"
                        name="status"
                        value={validation.values.status}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        disabled={!isWatchOwned}
                      >
                        <option value="Waiting">Waiting</option>
                        <option value="Received">Received</option>
                      </select>
                    </Col>
                  </FormGroup>

                  {/* Received Date Picker */}
                  {showReceivedDate && (
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Received Date
                      </Label>
                      <Col lg="10">
                        <FlatPickr
                          className="form-control"
                          name="receivedDate"
                          readOnly={!isWatchOwned}
                          options={{ dateFormat: "d M,Y" }}
                          value={validation.values.receivedDate}
                          onChange={(date) => handleDateChange("receivedDate", date)}
                          disabled={!isWatchOwned}
                        />
                        {isWatchOwned && (
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => validation.setFieldValue("receivedDate", '')}
                          >
                            Clear Date
                          </button>
                        )}
                      </Col>
                    </FormGroup>
                  )}
                  {/* Submit Button */}
                  {isWatchOwned && (
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <button className="btn btn-primary btn-block" type="submit">
                          Update Watch
                        </button>
                      </Col>
                    </Row>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default WatchViewEdit;
